import axios from "axios";
import salsify from 'salsify-experiences-sdk';

export default (function () {
    const styles = [].slice.apply(document.getElementsByClassName('product-details__style-button'));
	const sizeGroups = [].slice.apply(document.getElementsByClassName('size-group'));
	const sizeButtons = [].slice.apply(document.getElementsByClassName('product-details__size-button'));
	const primaryImage = document.getElementsByClassName('product-details__product-image--primary')[0];
	const loading = document.getElementsByClassName('loading-show')[0];
	const previewImage = document.getElementsByClassName('product-details__preview-images--primary')[0];
    const buyButtons = [].slice.apply(document.getElementsByClassName('product-details__cart-btn'));
    const buyButtonDirectComms = [].slice.apply(document.getElementsByClassName('product-details__cart-btn-directComm'));
    const priceHeader = document.getElementsByClassName('product-details__price')[0];
    const upcHeader = document.getElementsByClassName('product-details__upc')[0];
    const itemCodeHeader = document.getElementsByClassName('product-details__item-code')[0];
    const itemCodeHeader2 = document.getElementsByClassName('product-details__item-code2')[0];
	const productQuantity = document.getElementsByClassName('product-details__quantity-select')[0];
    const prodUPC = document.getElementById('prodUPC');
    const addToCartBox = document.getElementsByClassName('product-details__option product-details__purchase-option-online')[0];
    const restrictedItemPrice = document.getElementsByClassName('restricted-item-price-section')[0];
    const showConsumerPrice = document.getElementsByClassName('show-consumer-price')[0];
    const outOfStock = document.getElementsByClassName('out-of-stock')[0];
    const personalizationColor = document.getElementById('personalizationColor');
    const personalizedText = document.getElementById('personalizedText');
    const maxCharLimit = document.getElementById('max-pers-char-limit');
    
    styles.forEach((item, index) => {

		item.addEventListener('click', e => {
			e.preventDefault();

			styles.forEach((item, i) => {
                item.classList.remove('product-details__style-button--active');
			});

            if (e.currentTarget.classList.contains('product-details__style-button')) {
				const self = e.currentTarget;

                self.classList.add('product-details__style-button--active');

				SizeGroupDisplay(self.dataset.colorcode);
			}
        });

	});

    sizeButtons.forEach((item, index) => {

		item.addEventListener('click', e => {
			e.preventDefault();
			const self = e.currentTarget;

			sizeButtons.forEach((item, i) => {
				item.classList.remove('product-details__size-button--active');
			});

            self.classList.add('product-details__size-button--active');

			if (typeof window.coastal.getpriceurl !== "undefined") {
				loading.classList.add('loading-show');

				axios.post(window.coastal.getpriceurl, { ItemNumber: self.dataset.itemnumber })
                    .then((response) => {
                        //console.log(response);

						self.dataset.price = response.data;

						prodUPC.value = self.dataset.upc;

                        if (buyButtons.length !== 0) {
                            buyButtons.forEach(function (buyButton) {
                                buyButton.setAttribute('data-name', self.dataset.name);
                                buyButton.setAttribute('data-upc', self.dataset.upc);
                                buyButton.setAttribute('data-imagepath', self.dataset.imagepath);
                                buyButton.setAttribute('data-price', self.dataset.price);
                                buyButton.setAttribute('data-itemnumber', self.dataset.itemnumber);
                                buyButton.setAttribute('data-maxperschars', self.dataset.maxperschars)
                            });
                        }
                        if (buyButtonDirectComms.length !== 0) {
                            buyButtonDirectComms.forEach(function (buyButton) {
                                buyButton.setAttribute('data-itemnumber', self.dataset.itemnumber);
                            });
                        }

                        if (directCommPricing === true) {
                            UpdatePriceDirectComm(self.dataset.itemnumber.replace(/  +/g, ' '));
                        } else {
                            UpdatePrice(self.dataset.price);
                        }
                        UpdateItemCode(self.dataset.itemnumber);
                        UpdateUPC(self.dataset.upc);
                        if (self.dataset.maxperschars !== "") {
                            UpdateMaxChars(self.dataset.maxperschars)
                        }
                        //For Salsify Enhanced Content generation in Product-Details.cshtml
                        const productID = self.dataset.upc;
                        document.querySelector('#enhanced-content-container').innerHTML = '';
                        if (salsify.enhancedContent.exists(productID)) {
                            salsify.enhancedContent.renderIframe(document.querySelector('#enhanced-content-container'), productID)
                        }
                        SwitchImages(self.dataset.imagepath);
                    })
					.catch((error) => {
						loading.classList.remove('loading-show');
                        console.log(error);
                    });
            }
        });

    });
    if (buyButtonDirectComms.length !== 0) {
        buyButtonDirectComms[0].addEventListener("click", function() {
            var buyButton = buyButtonDirectComms[0];
            var cartItems = [];
            document.querySelector('#status-message').innerHTML = '';
            // Format the cartItems array.
            if (personalizationColor !== null && personalizedText !== null) {
                cartItems.push({
                    rsItemId: buyButton.getAttribute("data-itemnumber").replace(/  +/g, ' '),
                    rsItemUrl: buyButton.getAttribute("data-link"),
                    quantity: buyButton.getAttribute("data-quantity"),
                    rsProductPersonalizationLabel1: 'Personalization Color',
                    rsProductPersonalizationLabel2: 'Font',
                    rsProductPersonalizationLabel3: 'Text',
                    rsProductPersonalizationValue1: personalizationColor.value,
                    rsProductPersonalizationValue2: 'BLOCK',
                    rsProductPersonalizationValue3: personalizedText.value
                });
            }
            else {
                cartItems.push({
                    rsItemId: buyButton.getAttribute("data-itemnumber").replace(/  +/g, ' '),
                    rsItemUrl: buyButton.getAttribute("data-link"),
                    quantity: buyButton.getAttribute("data-quantity")
                });
            }
            console.log(cartItems);
            _rsProductApiClient.addItemsToCart(
                cartItems,
                function (parsedResponse, context) {
                    console.log(parsedResponse);
                    document.querySelectorAll('.rsCartCount').forEach(function (c) { c.innerHTML = parsedResponse['cart']['count']; });
                    
                    document.querySelector('#status-message').innerHTML = "Added items to cart and updated cart quantity.";
                }
            );
            //Capture consumer add to cart event for Salsify reporting
            salsify.events.addToCart({
                productIdType: 'SKUWITHCHECKDIGIT',
                productId: upcHeader.innerHTML,
                quantity: buyButton.getAttribute("data-quantity"),
                price: priceHeader.innerHTML
            });
        });
    }
    if (typeof productQuantity !== "undefined" && productQuantity !== null) {
        productQuantity.addEventListener('change', e => {
            UpdateQuantity();
        });
    }

    function UpdateQuantity() {
        if (buyButtons.length !== 0) {
            if (typeof productQuantity !== "undefined" && productQuantity !== null) {
                buyButtons.forEach(function (buyButton) {
                    buyButton.setAttribute('data-quantity', productQuantity.options[productQuantity.selectedIndex].value);
                });
            }
        }
        if (buyButtonDirectComms.length !== 0) {
            if (typeof productQuantity !== "undefined" && productQuantity !== null) {
                buyButtonDirectComms.forEach(function (buyButton) {
                    buyButton.setAttribute('data-quantity', productQuantity.options[productQuantity.selectedIndex].value);
                });
            }
        }
    }

    function SwitchImages(imageSrc) {
		primaryImage.setAttribute('src', imageSrc);
		previewImage.setAttribute('src', imageSrc);

		loading.classList.remove('loading-show');
	}

	function SizeGroupDisplay(colorcode) {
		//console.log(colorcode);

		sizeGroups.forEach((item, i) => {
			item.classList.remove('size-group--active');
		});

		const activeSizeGroup = sizeGroups.find(obj => {
			return obj.dataset.sizegroup === colorcode;
		});

        activeSizeGroup.classList.add('size-group--active');
        let groupSizeButtons = activeSizeGroup.getElementsByClassName('product-details__size-button');
        
        if (typeof groupSizeButtons[0] !== "undefined") {
            groupSizeButtons[0].click();
        }
    }
    function UpdatePriceDirectComm(itemCode) {
        const selectedProd = productList.find(product => {
            return product.context.itemId === itemCode;
        });
        if (selectedProd == null) {
            setTimeout(function () { UpdatePriceDirectComm(itemCode) }, 500);
        }
        else {
            if (selectedProd.prices == undefined) {
                showConsumerPrice.style.display = 'none';
                priceHeader.textContent = restrictedItemPrice.style.display = 'block';
                addToCartBox.style.display = 'none';
            }
            else if (selectedProd.inventory.inStock == false) {
                showConsumerPrice.style.display = 'none';
                priceHeader.textContent = outOfStock.style.display = 'block';
                addToCartBox.style.display = 'none';
            }
            else {
                showConsumerPrice.style.display = 'block';
                priceHeader.textContent = "$" + selectedProd.prices.sell;
                restrictedItemPrice.style.display = 'none';
                outOfStock.style.display = 'none';
                addToCartBox.style.display = 'inline-flex';
                }
        }
    }
    function UpdatePrice(price) {
        priceHeader.textContent = price;
    }

    function UpdateUPC(upc) {
        upcHeader.textContent = upc;
    }

    function UpdateItemCode(itemCode) {
        itemCodeHeader.textContent = itemCode;
        itemCodeHeader2.textContent = itemCode;
    }

    function UpdateMaxChars(maxChars) {
        maxCharLimit.textContent = maxChars;
    }

    if (typeof styles[0] !== "undefined") {
        styles[0].click();
    }

    if (typeof sizeButtons[0] !== "undefined") {
        sizeButtons[0].click();
    }

    UpdateQuantity();

})();